import { GA4Event } from "./GA4Event";

export default buildDialogViewEvent;

function buildDialogViewEvent(model) {
	const { dialog_name, component } = model.attributes;
	const { eventData } = new GA4Event("dialog_view", {
		dialog_name: dialog_name || "",
		component: component || ""
	});
	return eventData;
}
