import { GA4Event } from "./GA4Event";

export default buildSelectItemEvent;

function buildSelectItemEvent(model) {
	const { products, attributes } = model;
	const { component, list } = attributes;
	const event = new GA4Event("select_item", {
		item_list_id: component,
		item_list_name: list || component || ""
	});
	const { eventData } = event;
	const product = Object.assign(products[0], {
		component,
		list
	});
	const itemData = event.buildItem(product);
	eventData.params['items'] = [
		itemData
	];
	event.assignCustomProperties(attributes, eventData.params);
	return eventData;
}
