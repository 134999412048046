import { GA4Event } from "./GA4Event";

export function buildExpressionImpressionEvent(model, message) {
	const { attributes } = message;
	const baseParams = {
		exp_variant_string: attributes["exp_variant_string"],
		seitentyp: attributes["seitentyp"] || model["seitentyp"] || model.attributes["seitentyp"] || ""
	}
	const { eventData } = new GA4Event("experience_impression", baseParams);
	return eventData;
}
