import { GA4Event } from "./GA4Event";

export default buildAddShippingPaymentEvent;

function buildAddShippingPaymentEvent(eventName, model) {
	const { attributes, products } = model;
	
	const standardAttributes = {
		currency: attributes.currency,
		value: parseFloat(attributes.totalvalue)
	}

	switch (eventName) {
		case "add_shipping_info":
			standardAttributes.shipping_tier = attributes.shipping_tier;
			break;
		case "add_payment_info":
			standardAttributes.payment_type = attributes.payment_type;
			break;
		default:
			break;
	}
	const event = new GA4Event(eventName, standardAttributes);

	const { eventData } = event;
	const items = [];
	for (const product of products) {
		const itemData = event.buildItem(product);
		items.push(itemData);
	}
	eventData.params['items'] = items;
	return eventData;
}

