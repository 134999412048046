import { GA4Event } from "./GA4Event";

export default buildViewSearchResultsEvent;

function buildViewSearchResultsEvent(model) {
	const { attributes, products } = model;
	const event = new GA4Event("view_search_results", {
		search_term: attributes.suchbegriff
	})
	const { eventData } = event;
	const items = [];
	for (const product of products) {
		const productDataWithAttributes = Object.assign(product, attributes);
		const itemData = event.buildItem(productDataWithAttributes, true);
		items.push(itemData);
	}
	eventData.params["items"] = items;
	return eventData;
}
