import Cookies from "js-cookie";

const baseOptions = {
	path: "/",
	domain: window.location.hostname,
	expires: 365,
	secure: true
}

export function deleteCookie(cookie) {
	return Cookies.remove(cookie, baseOptions);
}

export function getCookie(cookie) {
	return Cookies.get(cookie);
}

export function setGaCookie(params) {
	const { cid } = params;
	deleteCookie("_ga");
	return Cookies.set("_ga", `GA1.1.${cid}`, baseOptions);
}

export function setGa4Cookie(params, ga4tid) {
	const { sid, sct, seg, mri } = params;
	const cookieName = `_ga_${ga4tid}`;
	deleteCookie(cookieName);
	return Cookies.set(cookieName, `GS1.1.${sid}.${sct}.${seg}.${mri}.0`, baseOptions);
}

export function setHitcounterCookie(value) {
	return Cookies.set("_ga_hitcounter", value, baseOptions);
}
