import getWebAnalyticsContainer from "../analytics-container";

export {
	pushKundennummerToDatalayer,
	getKundennummer
};

function pushKundennummerToDatalayer() {
	const datalayer = getWebAnalyticsContainer();
	const kundennummer = getKundennummer();
	datalayer.push({
		"kundennummer": kundennummer
	});
}

function getKundennummer() {
	if(cookieExistiertNicht('KUNDE')) {
		return "";
	}
	const cookieKundenNummer = atob(cookieWertAuslesen("KUNDE"));
	const kundennummer = cookieKundenNummer.substr(0, cookieKundenNummer.indexOf(":"))
    return kundennummer;
}

/**
 * Gibt true zurück, wenn das Cookie existiert
 * @param {string} name - Name des Cookies
 * @returns {boolean}
 */
 function cookieExistiertNicht(name) {
    return !document.cookie
        .split(';')
        .filter((item) => item
            .includes(name)).length;
}

function cookieWertAuslesen(name) {
    const cookieArray = document.cookie.split(";");
    for (let i = 0; i < cookieArray.length; i++) {
        const cookiePair = cookieArray[i].split("=");
        if (name === cookiePair[0].trim()) {
            return cookiePair[1];
        }
    }
    return "";
}
