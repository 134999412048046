import { GA4Event } from "./GA4Event";

export {
	getViewItemListEvents,
	buildViewItemListEvent
}

function getViewItemListEvents(message) {
	const { views } = message;
	const events = [];
	for (const [productlist, items] of Object.entries(views)) {
		const eventData = buildViewItemListEvent(items);
		events.push(eventData);
	}
	return events;
}

/**
 * Baut ein einzelnes Event für "view_item_list" zusammen
 * https://developers.google.com/analytics/devguides/collection/protocol/ga4/reference/events#view_item_list
 *
 * @param {object} views - Daten für eine Produktliste mit sämtlichen Produktimpressionen
 *
 * @returns {object}
 */
function buildViewItemListEvent(views) {
	const { component, list } = views[0];
	const event = new GA4Event("view_item_list", {
		item_list_id: component,
		item_list_name: list || component || ""
	}
	);
	const { eventData } = event;
	const items = [];
	for (const view of views) {
		const itemData = event.buildItem(view, true);
		items.push(itemData);
	}
	eventData.params['items'] = items;
	return eventData;
}
