import { GA4Event } from "./GA4Event";

export default buildSignupEvent;

function buildSignupEvent(model) {
	const { method } = model.attributes;
	const { eventData } = new GA4Event("sign_up", {
		method: method
	});
	return eventData;
}
