import { GA4Event } from "./GA4Event";

export default buildPageViewEvent;

function buildPageViewEvent(message) {
	const { event, attributes } = message;
	const { eventData, assignCustomProperties } = new GA4Event(event, {});
	assignCustomProperties(attributes, eventData.params);
	return eventData;
}
