import getWebAnalyticsContainer from "../analytics-container";

export {
	pushClientIdToDatalayer
};

function pushClientIdToDatalayer() {
	const datalayer = getWebAnalyticsContainer();
	const cid = getClientId();
	datalayer.push({
		"client-id": cid
	});
}

export function getClientId() {
	let cid = "";
	try {
		cid = localStorage.getItem("cid");
		if(typeof(cid) === "undefined" && cid === "") {
			cid = generateId();
			localStorage.setItem("cid", cid);
		}
	} catch(e) {
		console.warn('Cound not receive cid');
		return "";
	}
	return cid;
}

function generateId() {
	const firstPart = parseInt(Math.random()*10000000000, 10);
	const sencondPart = parseInt(Math.random()*10000000000, 10);
	return `${firstPart}.${sencondPart}`;
}
