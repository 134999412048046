export class ThaliaDatalayerHelper {
	constructor(datalayer) {
		this.datalayer = datalayer;
	}

	get(key) {
		const eventIndex = this._getLastEventIndex(key);
		if (eventIndex >= 0) {
			return this.datalayer[eventIndex];
		}
		const keyIndex = this._getLastKeyIndex(key)
		if (keyIndex >= 0) {
			return this.datalayer[keyIndex][key];
		};
		return null;
	}

	_getLastEventIndex(key) {
		return this.datalayer.map(message => message.event === key).lastIndexOf(true);
	}

	_getLastKeyIndex(key) {
		return this.datalayer.map(message => key in message).lastIndexOf(true);
	}
}
