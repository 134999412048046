// Google Analytics Dictionary Cheatsheet:
// https://www.thyngster.com/ga4-measurement-protocol-cheatsheet/
// https://www.thyngster.com/app-web-google-analytics-measurement-protocol-version-2
import { EVENT_DATA_TO_GTAG_DICT, ITEM_DATA_TO_GTAG_DICT } from "./data";
import { getCookie, setHitcounterCookie } from "./cookies";
import { isStaging } from "../analytics-utils";
import { getCurrencyCode } from "../../../helpers/currencyCodeHelper";
import { getConsentmodeData } from "../../../consent-mode/consent-mode";

export function getGtagParams(event, measurement_id, ga4tid) {
	const params = getEventBaseParams(event, measurement_id);
	const consentmodeParams = getConsentmodeData();
	const dynamicParams = getDynamicParams(event, ga4tid);
	const gtagDictParams = transformMeasurementProtocolToGtagParams(event);
	Object.assign(params, {
		...dynamicParams,
		...consentmodeParams,
		...gtagDictParams
	})
	if ("_ss" in params && "_et" in params) {
		// Beim Session Start Event darf "_et" nicht enthalten sein.
		// Sollte das doch der Fall sein, entfernen wir den Eintrag.
		delete params["_et"];
	}
	setHitcounterCookie(params["_s"]);
	params["ep.debug_data"] = getDebugData(params["mri"]);
	return params;
}

function getEventBaseParams(event, measurement_id) {
	const { name } = event;
	let hitcounter = Number(getCookie("_ga_hitcounter"));
	const params = {
		v: 2,
		en: name, // Eventname
		tid: measurement_id, // GA4 Measurement ID
		gtm: "45je32m0", // GTM Hash Info --> TODO GA4: Woher kommt dieser Wert?
		dl: window.location.href, // page_location
		dt: document.title, // page_title
		ul: (navigator.language || "").toLowerCase(), // language
		cu: getCurrencyCode(window.location), // currency
		sr: `${window.screen.width}x${window.screen.height}`, // screen_resolution
		uafvl: navigator.userAgent, // User Agent Full Version List,
		uap: navigator.platform, // User Agent Platform
		_p: Math.round(2147483647 * Math.random()), // Random Page Load Hash
		_s: typeof hitcounter === "NaN" ? 1 : hitcounter += 1, // hits count per page. Resets on new page load.
		frm: 0 // Anforderung aus SBE-10008
	};
	if (document.referrer && document.referrer !== "") {
		params["dr"] = document.referrer; // page_referrer
	}
	if (isStaging()) {
		params["_dbg"] = 1; // debug_mode
	}
	if (name === "page_view") {
		// https://hd.thalia.de/jira/browse/SBE-8427
		params["_gaz"] = 1; // create google join
	}
	return params;
}

function getDynamicParams(event, ga4tid) {
	const params = {};
	const cookieGa = getCookie("_ga");
	const cookieGa4 = getCookie(`_ga_${ga4tid}`);
	const appCookie = getCookie('app_ga4');

	let { sid, sct, seg, mri } = getGa4CookieData(cookieGa4);
	let cid;
	let uid = undefined;

	if (typeof appCookie !== "undefined") {
		const appCookieData = getAppCookieData(appCookie);

		sid = appCookieData['sid'];
		cid = appCookieData['cid'];
		uid = appCookieData['uid'];
	} else {
		cid = getGaCookieData(cookieGa)['cid'];
	}

	params["sid"] = parseInt(sid);
	params["sct"] = sct;
	params["seg"] = seg;
	params["cid"] = cid;
	if (typeof uid !== 'undefined') {
		params["uid"] = uid;
	}
	Object.assign(params, {
		...getNewVisitorParams(event, cookieGa4),
		...getSessionParams(event, sct, mri)
	});
	params["mri"] = new Date().getTime();
	// Für ca. 1% der Nutzer wird der Timestamp nicht richtig gesetzt (z.B. mit dem Jahr 1970)
	// Das führt dazu, dass der Nutzer bei jedem Event eine neue Session erzeugt.
	// Workaround: wir prüfen, ob der Timestamp gültig ist. Wenn das nicht der Fall ist
	// wird das Event ignoriert
	// Folgeticket: https://thaliamayersche.atlassian.net/browse/SBE-9482
	const invalidTimestamp = isInvalidTimestamp(params["mri"]);
	if (invalidTimestamp) {
		params["ignore"] = true;
	}
	return params;
}

export function getGa4CookieData(cookieGa4) {
	const params = {
		sid: undefined, // session_id
		cid: undefined, // client_id
		sct: 0, // ga_session_number (session count)
		mri: undefined, // most_recent_interaction
		seg: 0
	};
	if (typeof cookieGa4 === "undefined") {
		params["sid"] = new Date().getTime();
	} else {
		const cookieValues = cookieGa4.split(".");
		params["sid"] = cookieValues[2];
		params["sct"] = JSON.parse(cookieValues[3]);
		params["seg"] = JSON.parse(cookieValues[4]);
		params["mri"] = cookieValues[5];
	}
	return params;
}

function getGaCookieData(cookieGa) {
	const params = {
		cid: `${Math.floor(1000000000 + Math.random() * 9000000000)}.${new Date().getTime()}`
	};
	if (cookieGa) {
		const cookieValues = cookieGa.split(".");
		// eReader uuid Workaround: Kann ausgebaut werden,
		// wenn der eReader die client_id nicht mehr selber setzt
		if (cookieValues[3] === undefined) {
			params["cid"] = `${cookieValues[2]}`; // first visitor timestamp
		} else {
			params["cid"] = `${cookieValues[2]}.${cookieValues[3]}`; // first visitor timestamp
		}
	}
	return params;
}

function getAppCookieData(appCookie) {
	const params = {
		sid: undefined,
		cid: undefined,
		uid: undefined,
	};

	if (typeof appCookie !== "undefined") {
		const appCookieValues = appCookie.split('.');
		params['sid'] = appCookieValues[0];
		params['cid'] = `${appCookieValues[1]}.${appCookieValues[0]}`;
		params['uid'] = appCookieValues[2];
	}

	return params;
}

function getNewVisitorParams(event, cookieGa4) {
	const { name } = event;
	const params = {};
	if (name === "page_view" && typeof cookieGa4 === "undefined") {
		// console.debug("GA4: new_visitor");
		params["_fv"] = 1;
	}
	return params;
}

function getSessionParams(event, sct, mri) {
	const { name } = event;
	const params = {};
	const timeNow = new Date().getTime();
	if (mri) {
		// TODO GA4: Ggf. anders berechnen,
		// siehe dazu auch: https://www.thyngster.com/ga4-measurement-protocol-cheatsheet/
		params["_et"] = timeNow - mri;
	}
	if (mri && timeNow - mri > 10000) {
		params["seg"] = 1;
	}
	// const userIsIdle = timeNow - mri > 5000; // only for testing
	const userIsIdle = timeNow - mri > 1800000
	if (typeof mri === "undefined" || userIsIdle) {
		if (name === "page_view") {
			// Nach 30 Minuten Inaktivität (letztes Event) wird eine neue Session erzeugt
			// console.debug("GA4: new_session");
			params["sct"] = sct + 1;
			params["sid"] = timeNow;
			params["_ss"] = 1;
		} else {
			// Klick und View Events (onbeforeunload, scroll) müssen ignoriert werden,
			// wenn das letzte Event älter als 30 Minuten ist.
			// Das erste Event einer neuen Sesion muss "page_view" sein.
			// Wird vor dem Senden ausgelesen.
			// console.debug("GA4: ignore event: " + name)
			params["ignore"] = true;
		}
	}
	return params;
}

function transformMeasurementProtocolToGtagParams(event) {
	const { params } = event;
	const gtagDictParams = {};
	for (const eventParamKey in params) {
		const isGtagDictKey = !!EVENT_DATA_TO_GTAG_DICT[eventParamKey];
		if (isGtagDictKey) {
			const eventParam = getGtagEventParam(params, eventParamKey);
			Object.assign(gtagDictParams, eventParam);
			continue;
		}
		const isItemsKey = eventParamKey === "items";
		if (isItemsKey) {
			const itemParams = getGtagItemParams(params);
			Object.assign(gtagDictParams, itemParams);
			continue;
		}
		const isNumber = typeof params[eventParamKey] === "number";
		if (isNumber) {
			const customNumberParam = getGtagCustomParam(params, eventParamKey, true);
			Object.assign(gtagDictParams, customNumberParam);
			continue;
		}
		const customParam = getGtagCustomParam(params, eventParamKey);
		Object.assign(gtagDictParams, customParam);
	}

	return gtagDictParams;
}

function getGtagEventParam(params, eventParamKey) {
	const gtagParam = {};
	const gtagParamKey = EVENT_DATA_TO_GTAG_DICT[eventParamKey];
	gtagParam[gtagParamKey] = params[eventParamKey];
	return gtagParam;
}

function getGtagItemParams(params) {
	const itemParams = {};
	const { items } = params
	items.forEach((item, index, list) => {
		const { name, value } = getItemQueryparams(item, index);
		itemParams[name] = value;
	});
	return itemParams;
}

function getGtagCustomParam(params, eventParamKey, number = false) {
	const customParam = {};
	const customParamPrefix = number ? "epn." : "ep.";
	customParam[customParamPrefix + eventParamKey] = params[eventParamKey];
	return customParam;
}

/**
 * GA4 requires all item parameters to be in a single-string concatenated with a ~
 *
 * @param {*} itemData all item params to be added
 * @param {*} itemIndex index of the item. Duplicate indexes are not allowed
 * @returns string
 */
function getItemQueryparams(itemData, itemIndex) {
	const name = "pr" + (itemIndex + 1);
	let value = "";
	let itemCount = 0;
	for (const itemKey in itemData) {
		const gtagItemKey = ITEM_DATA_TO_GTAG_DICT[itemKey];
		if (gtagItemKey) {
			value += gtagItemKey + itemData[itemKey] + "~";
			continue;
		}
		value += getCustomItemParam(itemCount, itemKey, itemData[itemKey]);
		itemCount++;
	}
	return {
		name,
		value,
	};
}

/**
 * GA4 requries custom item parameters to be added with k0 and v0 prefixes (for no officially documented reason)
 *
 * @param {*} itemKey
 * @param {*} itemValue
 * @returns
 */
function getCustomItemParam(itemCount, itemKey, itemValue) {
	return `k${itemCount}${itemKey}~v${itemCount}${itemValue}~`;
}

export function getDoubleclickHitModel(event) {
	return {
		v: 2,
		tid: event["tid"],
		cid: event["cid"],
		gtm: event["gtm"],
		aip: 1,
		frm: 0,
		...getConsentmodeData()
	};
}

export function isInvalidTimestamp(mri) {
	const date = new Date(mri);
	const year = date.getUTCFullYear();
	return year < 2023;
}

function getDebugData(mri) {
	const now = new Date().getTime();
	return `${now},${mri}`;
}
