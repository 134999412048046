import { GA4Event } from "./GA4Event";

export default buildAddToWishlistEvent;

function buildAddToWishlistEvent(model) {
	const { attributes, products } = model;
	const event = new GA4Event("add_to_wishlist", {
		currency: attributes.currency,
		value: parseFloat(attributes.value)
	})
	const { eventData } = event;
	const items = [];
	for (const product of products) {
		const productDataWithAttributes = Object.assign(product, attributes);
		const itemData = event.buildItem(productDataWithAttributes, true);
		items.push(itemData);
	}
	eventData.params["items"] = items;
	return eventData;
}
