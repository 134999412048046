export default [
	"aenderunglieferadresse",
	"aenderungrechnungsanschrift",
	"environment",
	"clubstatus",
	"lieblingsfiliale",
	"filialsucheerfolg",
	"hoerbuchdownloadkaufart",
	"ean",
	"authentifizierung",
	"verfuegbarkeit",
	"kategoriepfadname",
	"seitentyp",
	"suchbegriff",
	"zahlart",
	"versandart",
	"querystring",
	"filter",
	"suchtreffer",
	"geraetekategorie",
	"firmwareversion",
	"ab_test",
	"kundensegmente",
	"referrer",
	"spellcheckeraktiv",
	"spellcheckervorschlag",
	"darstellung",
	"seitenart",
	"fusionpipeline",
	"produktseite",
	"produktab_test",
	"produktsuchbegriff",
	"user_id",
	"produktkomponente",
	"client_id",
	"fusionsuggestpipeline",
	"darstellung",
	"suchbegriffersetzung",
	"suchbegriffersetzunguebersteuert",
	"einloesunghdbcredits",
	"verkaufsrang",
	"umsatzgeschenkkarten",
	"umsatzgeschenkservice",
	"umsatzgutscheine",
	"umsatzrabatte",
	"suchergebnisse",
	"perf_lcp",
	"perf_fid",
	"perf_cls",
	"perf_fcp",
	"perf_inp",
	"perf_ttfb",
	"ab_test_search",
	"search_redirect",
	"search_term_origin",
	"debug_data",
	"produktart",
	"coveopipeline",
	"reco_fallback"
]
