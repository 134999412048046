import { ThaliaDatalayerHelper } from "../datalayer/datalayerHelper";
import getWebAnalyticsContainer from "../analytics/analytics-container";

export function getConsentmodeData() {
	const datalayer = getWebAnalyticsContainer();
	const consentmodedata = new Consentmodedata(datalayer);
	return {
		gcs: consentmodedata.getGcsValue(),
		gcd: consentmodedata.getGcdValue(),
		dma: consentmodedata.getDmaValue(),
		dma_cps: consentmodedata.getDmaCpsValue(),
		npa: consentmodedata.getNpaValue()
	}
}

export class Consentmodedata {
	constructor(datalayer) {
		this.datalayer = datalayer;
		this.helper = new ThaliaDatalayerHelper(this.datalayer);
	}

	get como_default() {
		return this.helper.get("como_default");
	}

	get como_update() {
		return this.helper.get("como_update");
	}

	get ad_storage() {
		if (!this.como_update) {
			return null;
		}
		return this.como_update["ad_storage"];
	}

	get analytics_storage() {
		if (!this.como_update) {
			return null;
		}
		return this.como_update["analytics_storage"];
	}

	getServices(type) {
		if (type === "default") {
			return this.como_default.services;
		}
		return this.como_update.services;
	}

	getDmaValue() {
		// EU = 1, non EU = 0
		if (window.location.href.includes("orellfuessli")) {
			return 0;
		}
		return 1;
	}

	getDmaCpsValue() {
		return "syphamo";
	}

	getGcsValue() {
		const { analytics_storage, ad_storage } = this.getServices("update");
		if (analytics_storage === "granted" && ad_storage === "granted") {
			return "G111";
		} else if (analytics_storage === "granted" && ad_storage === "denied") {
			return "G101";
		} else if (analytics_storage === "denied" && ad_storage === "granted") {
			return "G110";
		} else {
			return "G100";
		}
	}

	getGcdValue() {
		const analytics_storage = this.getGcdLetter("analytics_storage");
		const ad_storage = this.getGcdLetter("ad_storage");
		return `11${ad_storage}1${analytics_storage}1${ad_storage}1${ad_storage}5`;
	}

	getGcdLetter(service) {
		let letter;
		// Es werden keine GA4 Events versendent, wenn kein "como_update" erfolgt ist.
		// Es reicht hier daher aus den Buchstaben für "update" zu ermitteln
		const gcdtype = this.getGcdType(service);
		if (!gcdtype) return undefined;
		switch (gcdtype) {
			case "default-denied":
				// Einstellung “denied” durch “default” Einstellung
				letter = "p";
				break;
			case "default-granted":
				// Einstellung “granted” durch “default” Einstellung
				letter = "t";
				break;
			case "update-denied":
				// Einstellung “denied” durch ein “update”
				letter = "q";
				break;
			case "update-granted":
				// Einstellung “granted” durch ein “update”
				letter = "r";
				break;
			case "update-denied-nodefault":
				// Einstellung “denied” durch ein “update” ohne vorherige “default”
				letter = "m";
				break;
			case "update-granted-nodefault":
				// Einstellung “granted” durch ein “update” ohne vorherige “default”
				letter = "n";
				break;
			case "update-denied-defaultgranted":
				// Einstellung “denied” durch ein “update”, nachdem der “default” Wert “granted” war
				letter = "u";
				break;
			case "update-granted-defaultgranted":
				// Einstellung “granted” durch ein “update”, nachdem der “default” Wert bereits “granted” war
				letter = "v";
				break;
			default:
				// Einstellung wurde nicht definiert
				letter = "l";
				break;
		}
		return letter;
	}

	getGcdType(service) {
		const status = this.como_update["services"][service];
		if (!this.como_default) {
			return `update-${status}-nodefault`;
		}
		const defaultStatus = this.como_default["services"][service];
		if (defaultStatus === "granted") {
			return `update-${status}-defaultgranted`;
		}
		return `update-${status}`;
	}

	getNpaValue() {
		// nicht vorhanden, wenn Zustimmung besteht
		// bei fehlender Zustimmung (zu personalisierter Werbung) mit einer “1” belegt
		const { ad_storage } = this.getServices("update");
		if (ad_storage === "granted") {
			return 0;
		}
		return 1;
	}
}
