// import "data-layer-helper/dist/data-layer-helper";
// import getWebAnalyticsContainer from "../analytics-container";
// import { sendNielsenTracking } from "./nielsen-tracking";
import { pushClientIdToDatalayer } from "./cid";
import { pushKundennummerToDatalayer } from "./kundennummer";
pushClientIdToDatalayer();
pushKundennummerToDatalayer();

// const tracker = {};

// registerTracker("page", (model, message) => {
// 	return sendNielsenTracking(message);
// });

// observe(getWebAnalyticsContainer());
// function observe(observable) {
// 	// eslint-disable-next-line no-new, no-undef
// 	DataLayerHelper(observable, listener, true);
// }

// function listener(message, model) {
// 	const eventTracker = tracker[model.event];
// 	if (eventTracker) {
// 		eventTracker(model, message);
// 	}
// }

// function registerTracker(event, callback) {
// 	tracker[event] = callback;
// }
