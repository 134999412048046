import { GA4Event } from "./GA4Event";

export {
	getViewPromotionEvents,
	buildViewPromotionEvent
};

function buildViewPromotionEvent(promotion) {
	const { component, position, promotion_name, promotion_creative } = promotion;
	const event = new GA4Event("view_promotion", {
		creative_name: promotion_creative,
		creative_slot: position.toString(),
		promotion_id: component,
		promotion_name: promotion_name
	});
	const { eventData } = event;
	eventData.params["items"] = [];
	return eventData;
}

function getViewPromotionEvents(message) {
	const events = [];
	Object.values(message.promotions).forEach(promotion => {
		const eventData = buildViewPromotionEvent(promotion);
		events.push(eventData);
	});
	return events;
}
