import { GA4Event } from "./GA4Event";

export default buildBeginCheckoutEvent;

function buildBeginCheckoutEvent(model) {
	const { attributes, products } = model;
	const event = new GA4Event("begin_checkout", {
		currency: attributes.currency,
		value: parseFloat(attributes.totalvalue),
		step: attributes.step
	});
	const { eventData } = event;
	const items = [];
	for (const product of products) {
		const itemData = event.buildItem(product);
		items.push(itemData);
	}
	eventData.params['items'] = items;
	return eventData;
}
