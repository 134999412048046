import { GA4Event } from "./GA4Event";

export default buildCopyEvent;

function buildCopyEvent(model) {
	const { attributes } = model;
	const { eventData } = new GA4Event("copy", {
		...attributes
	});
	return eventData;
}
