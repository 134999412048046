import {
	GTAG_ENDPOINT,
	DOUBLECLICK_ENDPOINT,
	getGA4MeasurementId,
	getGA4MeasurementIdForSgtm,
	getGa4tid,
} from "./analytics-utils";
import { isEreader } from "../../helpers/ereader/isereader";
import { getGtagParams, getDoubleclickHitModel } from "./gtagEvents/mp-to-gtag";
import { didUserOptOut } from "../ga-optout-cookie";
import { setGaCookie, setGa4Cookie, getCookie } from "./gtagEvents/cookies";
import { isWebStaging } from "./analytics-utils";

const measurement_id = getGA4MeasurementId();
const ga4tid = getGa4tid(measurement_id);

export default send;

/**
 * Sendet Events Richtung Analytics
 * https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events?client_type=gtag#send_an_event
 *
 * @param {object} events - Array/Liste mit 1 - n Events
 *
 */
async function send(events) {
	let ga4Cookie = "";
	let currentGA4CookieValue = "";
	try {
		const userOptOut = didUserOptOut(document.cookie, measurement_id);
		if (userOptOut) {
			return false;
		}
		ga4Cookie = `_ga_${ga4tid}`;
		currentGA4CookieValue = getCookie(ga4Cookie);
		// Umwandlung der Measurement Protocol Events für das inoffizielle (gtag) Protocol.
		// Siehe dazu auch https://hd.thalia.de/jira/browse/SBE-8103
		const gtagEvents = [];
		for (const event of events) {
			const gtagEvent = getGtagParams(event, measurement_id, ga4tid);
			console.info("consent", "send", event.name);
			gtagEvents.push(gtagEvent);
		}
		if ("ignore" in gtagEvents[0]) {
			return false;
		}
		const { requestQueryString, requestBody } = getGtagRequestData(gtagEvents);
		await sendData(GTAG_ENDPOINT, requestQueryString, requestBody);
		// send doubleclick on session start
		const sessionStart = (requestQueryString && requestQueryString.indexOf("_ss") !== -1);
		if (sessionStart) {
			const doubleclickModel = getDoubleclickHitModel(gtagEvents[0]);
			const doubleclickQuerystring = objectToQueryString(doubleclickModel);
			await sendData(DOUBLECLICK_ENDPOINT, doubleclickQuerystring);
		}
		const params = gtagEvents[0];
		setGaCookie(params);
		setGa4Cookie(params, ga4tid);

		sendViaGtag(events);
	} catch (error) {
		console.debug(`Fehler beim senden des GA4 Events: ${events[0].name}`);
		console.error(error);
	}
}

async function sendData(endpoint, querystring, body = null) {
	if (isEreader) {
		// eReader unterstützen sendBeacon nicht, daher bleiben wir hier zunächst beim fetch (mit polyfill)
		return await sendDataForEreader(endpoint, querystring, body = null);
	}
	// https://thaliamayersche.atlassian.net/browse/SBE-9437
	return navigator.sendBeacon(endpoint + "?" + querystring, body);
}

async function sendDataForEreader(endpoint, querystring, body = null) {
	const options = {
		method: "POST",
		keepalive: true
	}
	if (body) {
		Object.assign(options, {
			body: body
		})
	}
	try {
		await fetch(endpoint + "?" + querystring, options);
	} catch (error) {
		console.error(error);
	}
}

// Anleitung siehe: https://www.thyngster.com/app-web-google-analytics-measurement-protocol-version-2
function getGtagRequestData(events) {
	let requestBody, requestQueryString;
	const { length } = events;
	// Base Event Model for Web Hit
	const firstEvent = events[0];
	const baseEventModel = getBaseEventModel(firstEvent);
	if (length > 0) {
		// If there's only one event, we'll not pushing a body within our request
		if (length === 1) {
			Object.assign(baseEventModel, firstEvent);
		} else {
			requestBody = events.map(event => {
				return (objectToQueryString(event, false));
			}).join("\n");
		}
		requestQueryString = objectToQueryString(baseEventModel);
	}
	return { requestBody, requestQueryString }
}

function getBaseEventModel(event) {
	return {
		v: 2,
		tid: event["tid"],
		_p: event["_p"],
		sr: event["sr"],
		ul: event["ul"],
		cid: event["cid"],
		dl: event["dl"],
		dr: event["dr"],
		dt: event["dt"],
		sid: event["sid"],
		_s: event["_s"]
	}
}

function objectToQueryString(model, encode = true) {
	return Object.keys(model)
		.map((key) => `${key}=${encode ? encodeURIComponent(model[key]) : model[key]}`)
		.join('&');
}

function sendViaGtag(events) {
	console.debug("sendViaGtag", events);
	for (const event of events) {
		sendGtag(event);
	}
}

function insertGtag() {
	if (typeof window.gtag !== "function" || !isWebStaging()) {
		return;
	}
	const locationOrigin = window.location.origin;
	const measurement_id = getGA4MeasurementIdForSgtm();
	const script = document.createElement("script");
	script.src = `${locationOrigin}/tag/gtag/js?id=${measurement_id}&l=newDataLayer`;
	script.type = "text/javascript";
	script.async = 1;
	document.getElementsByTagName("head")[0].appendChild(script);
	gtag("config", measurement_id, {
		"send_page_view": false,
		"server_container_url": `${locationOrigin}/tag/`,
		"debug_mode": isWebStaging()
	});
}

insertGtag();

function sendGtag(event) {
	if (!isWebStaging()) {
		return;
	}
	gtag("event", event.name, event.params);
}