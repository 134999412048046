import { GA4Item } from "./GA4Item";
import { GA4CustomProperties } from "./GA4CustomProperties";

export { GA4Event };

/**
 * Klasse zum Erstellen eines GA4 Events.
 * Alle Events siehe: https://developers.google.com/analytics/devguides/collection/protocol/ga4/reference/events
 *
 * @param {string} name - Der Name des GA4 Events, z.B. "select_item"
 * @param {object} baseParams - Die base Parameter des GA4 Events, z.B. "item_list_id"
 *
 * @returns {object}
 */
class GA4Event {
	constructor(name, baseParams = {}) {
		this.name = name;
		this.baseParams = baseParams;
	}

	get eventData() {
		return this.build();
	}

	build() {
		const eventData = {};
		eventData['name'] = this.name;
		if (this.baseParams) {
			eventData['params'] = this.baseParams;
		}
		return eventData;
	}

	/**
	 * Methode zum Erstellen eines Itemobjekts
	 *
	 * @param {string} itemParams - alle Daten, die zum Produkt gesammelt wurden
	 * @param {boolean} customPropertiesOnItemLevel - Sollen die Custom Properties auf Item Ebene zugewiesen werden
	 *
	 * @returns {object}
	 */
	buildItem(itemParams, customPropertiesOnItemLevel = false) {
		const itemData = {};
		const { itemParameters } = new GA4Item(itemParams);
		Object.assign(itemData, itemParameters);
		if (!customPropertiesOnItemLevel) {
			return itemData;
		}
		this.assignCustomProperties(itemParams, itemData);
		return itemData;
	}

	/**
	 * Methode, die Custom Properties erzeugt und einem Datenobjekt zuweist
	 *
	 * @param {string} attributes - alle Daten, die zu einem Event gesammelt wurden
	 * @param {boolean} obj - das Objekt, dem die Custom Properties zugewiesen werden sollen
	 *
	 * @returns {object}
	 */
	assignCustomProperties(attributes, obj = {}) {
		const { validCustomProperties } = new GA4CustomProperties(attributes);
		if (validCustomProperties) {
			Object.assign(obj, validCustomProperties);
		}
		return obj;
	}
}
