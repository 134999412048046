import { getGA4MeasurementId } from "./ga4/analytics-utils";

export { didUserOptOut };

const measurement_id = getGA4MeasurementId();

function didUserOptOut(cookies, measurement_id) {
	const key = `ga-disable-${measurement_id}`;
	if (cookies.indexOf(`${key}=true`) === -1) {
		return false;
	}
	return true;
}

if (typeof window.gaOptOut !== "function") {
	window.gaOptOut = function () {
		document.cookie = `ga-disable-${measurement_id}=true; expires = Thu, 31 Dec 2099 23: 59: 59 UTC; path = /`;
		window[`ga-disable-${measurement_id}`] = true;
		alert(`Ihr Widerspruch wurde registriert und ihr Browser wurde erfolgreich von der Analyse des Surfverhaltens ausgeschlossen. Ihr Widerspruch wurde durch ein Cookie namens ga-disable-${measurement_id} festgehalten. Bitte entfernen Sie dieses Cookie nicht, andernfalls muss erneut Widerspruch einlegt werden.`);
	}
}
