import { GA4Event } from "./GA4Event";

export default buildFormEvent;

function buildFormEvent(model) {
	const { component, interaction, label } = model;
	const event = new GA4Event("form", {
		component: component,
		interaction: interaction,
		label: label
	});
	const { eventData } = event;
	const items = [];
	eventData.params["items"] = items;
	return eventData;
}
