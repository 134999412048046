import { GA4Event } from "./GA4Event";

export default buildViewItemEvent;

function buildViewItemEvent(model) {
	const { attributes, products } = model;
	const item = products[0];
	const event = new GA4Event("view_item", {
		currency: attributes.currency,
		value: parseFloat(attributes.value)
	});
	const { eventData } = event;
	const itemData = event.buildItem(item);
	eventData.params['items'] = [
		itemData
	];
	return eventData;
}
