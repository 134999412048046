import { GA4Event } from "./GA4Event";

export default buildPurchaseEvent;

function buildPurchaseEvent(model) {
	const { attributes, products } = model;
	const event = new GA4Event("purchase", {
		currency: attributes.currencycode,
		transaction_id: attributes.id,
		value: parseFloat(attributes.revenue),
		affiliation: attributes.affiliation,
		coupon: attributes.coupon,
		shipping: parseFloat(attributes.shipping),
		zahlart: attributes.zahlart,
		versandart: attributes.versandart,
		umsatzgeschenkkarten: attributes.umsatzgeschenkkarten,
		umsatzgeschenkservice: attributes.umsatzgeschenkservice,
		umsatzgutscheine: attributes.umsatzgutscheine,
		umsatzbrutto: attributes.umsatzbrutto,
		umsatzrabatte: attributes.umsatzrabatte,
		umsatzversandkosten: attributes.umsatzversandkosten
	});
	const { eventData } = event;
	const items = [];
	for (const product of products) {
		const itemData = event.buildItem(product, true);
		items.push(itemData);
	}
	eventData.params["items"] = items;
	return eventData;
}
