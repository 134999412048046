import "data-layer-helper/dist/data-layer-helper";
import send from "./send";
import getWebAnalyticsContainer from "../analytics-container";
import {
	buildPageViewEvent,
	buildSelectItemEvent,
	buildSearchEvent,
	buildViewSearchResultsEvent,
	buildLoginEvent,
	buildSignupEvent,
	buildViewItemEvent,
	buildPurchaseEvent,
	buildClickEvent,
	buildCopyEvent,
	buildFormEvent,
	buildCartEvents,
	buildAddToWishlistEvent,
	buildBeginCheckoutEvent,
	buildSelectPromotionEvent,
	buildAddShippingPaymentEvent,
	buildDialogViewEvent,
	getViewItemListEvents,
	getViewPromotionEvents,
	buildVideoEvent,
	buildExpressionImpressionEvent
} from "./events"
import { setHitcounterCookie } from "./gtagEvents/cookies";
import { CONSENT_UPDATE_EVENT_NAME } from "../../consent-mode/ucEventHandler";

const dataLayer = getWebAnalyticsContainer();
let ga4EventQueue = [];
let comoUpdateSent = false;
let pageViewSent = false;

setHitcounterCookie(0);

observe(dataLayer);

function observe(dataLayer) {
	return new DataLayerHelper(dataLayer, listener, true);
}

async function listener(model, message) {
	const { event } = message;
	const eventData = getEventData(model, message);
	if (event === CONSENT_UPDATE_EVENT_NAME) {
		comoUpdateSent = true;
		return await sendEventsFromQueue();
	}
	if (!eventData) return;
	if (pageViewSent) {
		return await sendData(eventData);
	}
	if (comoUpdateSent) {
		pushToQueue(eventData);
		if (event === "page_view") {
			return await sendEventsFromQueue();
		}
	} else {
		pushToQueue(eventData);
	}
}

async function sendEventsFromQueue() {
	if (ga4EventQueue.length === 0) return;
	// beim Aufruf dieser Funktion muss das page_view Event enthalten sein
	const pageView = getPageviewEvent();
	if (pageView) {
		await sendData([pageView]);
		pageViewSent = true;
		for (const eventFromQueue of ga4EventQueue) {
			if (eventFromQueue.name !== "page_view") {
				await sendData([eventFromQueue]);
			}
		}
		return ga4EventQueue = [];
	}
}

function getPageviewEvent() {
	return ga4EventQueue.find(event => event.name === "page_view");
}

function pushToQueue(eventData) {
	if (eventData) {
		return ga4EventQueue.push(eventData[0]);
	}
}

async function sendData(eventData) {
	if (eventData) {
		return await send(eventData);
	}
	return false;
}

function getEventData(model, message) {
	const { event } = message;
	let eventData = null;
	switch (event) {
		case "page_view":
			// SBE-7876
			eventData = buildPageViewEvent(message);
			break;
		case "login":
			eventData = buildLoginEvent(message);
			break;
		case "sign_up":
			eventData = buildSignupEvent(message);
			break;
		case "search":
			eventData = buildSearchEvent(message);
			break;
		case "searchresults":
			eventData = buildViewSearchResultsEvent(message);
			break;
		case "select_item":
			eventData = buildSelectItemEvent(message);
			break;
		case "view_item":
			eventData = buildViewItemEvent(message);
			break;
		case "purchase":
			eventData = buildPurchaseEvent(message);
			break;
		case "click":
			eventData = buildClickEvent(message);
			break;
		case "copy":
			eventData = buildCopyEvent(message);
			break;
		case "form":
			eventData = buildFormEvent(message);
			break;
		case "add_to_cart":
			eventData = buildCartEvents(event, message);
			break;
		case "view_cart":
			eventData = buildCartEvents(event, message);
			break;
		case "remove_from_cart":
			eventData = buildCartEvents(event, message);
			break;
		case "add_to_wishlist":
			eventData = buildAddToWishlistEvent(message);
			break;
		case "begin_checkout":
			eventData = buildBeginCheckoutEvent(message);
			break;
		case "select_promotion":
			eventData = buildSelectPromotionEvent(message);
			break;
		case "add_shipping_info":
			eventData = buildAddShippingPaymentEvent(event, message);
			break;
		case "add_payment_info":
			eventData = buildAddShippingPaymentEvent(event, message);
			break;
		case "dialog_view":
			eventData = buildDialogViewEvent(message);
			break;
		case "view_items":
			eventData = getViewItemListEvents(message);
			break;
		case "view_promotions":
			eventData = getViewPromotionEvents(message);
			break;
		case "video_start":
		case "video_progress":
		case "video_complete":
			eventData = buildVideoEvent(message);
			break;
		case "experience_impression":
			eventData = buildExpressionImpressionEvent(model, message);
			break;
		default:
			eventData = null;
			break;
	}
	if (!eventData) {
		return false;
	}
	const multipleEvents = Array.isArray(eventData);
	if (multipleEvents) {
		// u.a. view_items und view_promotions
		return getEventListData(eventData);
	}
	// u.a. page_view, select_item und view_item
	return [
		getSingleEventData(eventData)
	];
}

function getEventListData(eventData) {
	const eventList = [];
	for (const event of eventData) {
		const data = getSingleEventData(event);
		eventList.push(data);
	}
	return eventList;
}

function getSingleEventData(eventData) {
	const data = {
		...eventData
	};
	const utmData = getCustomUTMParameters();
	Object.assign(data.params, utmData);
	return data;
}

function getCustomUTMParameters() {
	const utmData = {};
	const searchparams = new URLSearchParams(location.search);
	const utm_params = ["utm_source", "utm_medium", "utm_campaign"];
	for (const param of utm_params) {
		if (searchparams.has(param)) {
			utmData[param] = searchparams.get(param);
		}
	}
	return utmData;
}