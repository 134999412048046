export {
	EVENT_DATA_TO_GTAG_DICT,
	ITEM_DATA_TO_GTAG_DICT
}

const EVENT_DATA_TO_GTAG_DICT = {
	currency: "cu",
	campaign_medium: "cm",
	campaign_source: "cs",
	campaign_name: "cn",
	campaign_content: "cc",
	screen_resolution: "sr",
	language: "ul",
	client_id: "cid",
	user_id: "uid",
	session_id: "sid",
	ip_override: "_uip",
	name: "en",
	engagement_time_msec: "_et",
	ga_session_number: "sct",
	page_location: "dl",
	page_title: "dt",
	page_referrer: "dr",
	measurement_id: "tid",
	debug_mode: "_dbg",
	item_list_id: "li",
	item_list_name: "ln"
};

const ITEM_DATA_TO_GTAG_DICT = {
	item_id: "id",
	item_name: "nm",
	affiliation: "af",
	coupon: "cp",
	discount: "ds",
	item_brand: "br",
	item_category: "ca",
	item_category2: "c2",
	item_category3: "c3",
	item_category4: "c4",
	item_category5: "c5",
	index: "lp",
	item_list_id: "li",
	item_list_name: "ln",
	item_variant: "va",
	location_id: "lo",
	price: "pr",
	quantity: "qt"
};
