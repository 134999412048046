import { getLowerCaseObject } from "../analytics-utils";
import allCustomProperties from "../custom-properties";

export { GA4CustomProperties }

/**
 * Klasse zum Erstellen gültiger Custom Properties.
 * Prüft, ob Custom Property gültig ist und
 * gibt ein Array aus key/value Objekten zurück.
 *
 * @param {object} properties - alle Properties eines DataLayer Objektes
 *
 * @returns {object}
 */
class GA4CustomProperties {
	constructor(properties) {
		this.properties = properties;
	}

	get validCustomProperties() {
		return this.build();
	}

	build() {
		const lowerKeyObject = getLowerCaseObject(this.properties);
		const properties = Object.entries(lowerKeyObject);
		const validProperties = {};
		for (const [key, value] of properties) {
			const validPropKey = validateKey(key);
			const validPropValue = validateValue(value);
			if (validPropKey && validPropValue) {
				Object.assign(validProperties, {
					[key]: value
				})
			}
		}
		return validProperties;
	}
}

function validateKey(key) {
	return allCustomProperties.includes(key);
}

function validateValue(value) {
	return typeof value !== "object" && value !== "";
}
