export { GA4Item };

/**
 * Klasse zum Erstellen eines GA4 Item Objects.
 *
 * @param {object} item - alle Daten, die zum Produkt gesammelt wurden
 *
 * @returns {object}
 */
class GA4Item {
	constructor(item) {
		this.item = item;
	}

	get itemParameters() {
		return this.build();
	}

	get itemComponent() {
		if (this.item.produktkomponente) {
			// productAttribution: localStorage ArtikelKlick
			return this.item.produktkomponente;
		}
		return this.item.component;
	}

	get itemProductlist() {
		if (this.item.produktliste) {
			// productAttribution: localStorage ArtikelKlick
			return this.item.produktliste;
		}
		if (this.item.list) {
			return this.item.list;
		}
		if (this.item.productlist) {
			return this.item.productlist;
		}
		if (this.itemComponent) {
			return this.itemComponent;
		}
		return;
	}

	get itemId() {
		if (this.item.id) {
			return this.item.id;
		}
		return this.item.pid;
	}

	get itemName() {
		return this.item.name;
	}

	get itemPosition() {
		return parseInt(this.item.position) || parseInt(this.item.index);
	}

	get itemPrice() {
		return parseFloat(this.item.price);
	}

	get itemCurrency() {
		return this.item.currency;
	}

	get itemDiscount() {
		return this.item.discount;
	}

	get itemQuantity() {
		return parseInt(this.item.quantity);
	}

	get produktart() {
		return this.item.produktart;
	}

	get recoFallback() {
		return this.item["reco_fallback"];
	}

	get itemCategory() {
		return this.getCategory(0);
	}

	get itemCategory2() {
		return this.getCategory(1);
	}

	get itemCategory3() {
		return this.getCategory(2);
	}

	get itemCategory4() {
		return this.getCategory(3);
	}

	get itemCategory5() {
		return this.getCategory(4);
	}

	getCategory(index) {
		if(!this.item.category) {
			return undefined;
		}
		return this.item.category.split("/")[index] || "";
	}

	build() {
		const itemObj = {};
		itemObj['item_id'] = this.itemId;
		if (this.itemComponent) {
			itemObj['item_list_id'] = this.itemComponent;
		}
		if (this.itemProductlist || this.itemComponent) {
			itemObj['item_list_name'] = this.itemProductlist;
		}
		if (this.itemName) {
			itemObj['item_name'] = this.itemName;
		}
		if (this.itemPosition) {
			itemObj['index'] = this.itemPosition;
		}
		if (this.itemPrice) {
			itemObj['price'] = this.itemPrice;
		}
		if (this.itemCurrency) {
			itemObj['currency'] = this.itemCurrency;
		}
		if (this.itemDiscount) {
			itemObj['discount'] = this.itemDiscount;
		}
		if (this.itemQuantity) {
			itemObj['quantity'] = this.itemQuantity;
		}
		if (this.itemCategory) {
			itemObj['item_category'] = this.itemCategory;
		}
		if (this.itemCategory2) {
			itemObj['item_category2'] = this.itemCategory2;
		}
		if (this.itemCategory3) {
			itemObj['item_category3'] = this.itemCategory3;
		}
		if (this.itemCategory4) {
			itemObj['item_category4'] = this.itemCategory4;
		}
		if (this.itemCategory5) {
			itemObj['item_category5'] = this.itemCategory5;
		}
		if (this.produktart) {
			itemObj['produktart'] = this.produktart;
		}
		if (this.recoFallback) {
			itemObj['reco_fallback'] = this.recoFallback;
		}
		return itemObj;
	}
}
