import { GA4Event } from "./GA4Event";

export default buildSelectPromotionEvent;

function buildSelectPromotionEvent(message) {
	const promotion = message.attributes;
	const event = new GA4Event("select_promotion", {
		promotion_id: promotion.component,
		promotion_name: promotion.promotion_name,
		creative_name: promotion.promotion_creative,
		creative_slot: ("" + promotion.position)
	});
	const { eventData } = event;
	return eventData;
}
