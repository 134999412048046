export {
	isStaging,
	isWebStaging,
	GTAG_ENDPOINT,
	DOUBLECLICK_ENDPOINT,
	getLowerCaseObject,
	getGA4MeasurementId,
	getGA4MeasurementIdForSgtm,
	getGa4tid
}

const GTAG_ENDPOINT = "https://region1.google-analytics.com/g/collect";
const DOUBLECLICK_ENDPOINT = "https://stats.g.doubleclick.net/g/collect";

function getGa4tid(measurement_id) {
	if (!measurement_id) {
		return null;
	}
	return measurement_id.split("-")[1] // measurement_id ohne "G-"
}

function getGA4MeasurementId() {
	let elementName = "ga-measurementId";
	if (isStaging()) {
		elementName = "ga-measurementId-staging";
	}
	const id = document.querySelector(`[name=${elementName}]`) ? document.querySelector(`[name=${elementName}]`).content : false;
	return id;
}

function getGA4MeasurementIdForSgtm() {
	let elementName = "ga-measurementId-sgtm";
	if (isStaging()) {
		elementName = "ga-measurementId-sgtm-staging";
	}
	const id = document.querySelector(`[name=${elementName}]`) ? document.querySelector(`[name=${elementName}]`).content : false;
	return id;
}

function getLowerCaseObject(obj) {
	const lowerKeyObject = {};
	for (const [key, value] of Object.entries(obj)) {
		lowerKeyObject[key.toLowerCase()] = value;
	}
	return lowerKeyObject;
}

function isStaging() {
	const isTestEnvironmentEreader = window.location.origin.includes("ereader-integ");
	const isTestEnvironmentWeb = window.location.origin.includes("integration");
	const isTestEnvironment = !!(isTestEnvironmentEreader || isTestEnvironmentWeb);
	return isTestEnvironment;
}

function isWebStaging() {
	return window.location.origin.includes("integration") && !window.location.origin.includes("app-");
}
