import { GA4Event } from "./GA4Event";

export default buildLoginEvent;

function buildLoginEvent(model) {
	const { method } = model.attributes;
	const { eventData } = new GA4Event("login", {
		method: method,
	});
	return eventData;
}
