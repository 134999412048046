import { GA4Event } from "./GA4Event";

export default buildSearchEvent;

function buildSearchEvent(model) {
	const { attributes } = model;
	const baseParams = {
		search_term: attributes["search_term"],
		search_redirect: "search_redirect" in attributes
	}
	if ("search_term_origin" in attributes) {
		baseParams["search_term_origin"] = attributes["search_term_origin"];
	}
	if ("ab_test_search" in attributes) {
		baseParams["ab_test_search"] = attributes["ab_test_search"];
	}
	const { eventData } = new GA4Event("search", baseParams);
	return eventData;
}
